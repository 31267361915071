import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

import bus from "@/plugins/bus";
export default {
  name: "deviceFrameAdd",
  mixins: [mixins],
  data() {
    return {
      edit: false,
      warehouses: [],
      //仓库
      formModel: {
        id: null,
        frameCode: null,
        manufacturer: null,
        plateNo: null,
        plateTime: null,
        insureCode: null,
        insureCompany: null,
        insureEnd: null,
        insureBegin: null,
        insureName: null,
        dates: []
      },
      formRules: {
        warehouseId: [{
          required: true,
          message: '请输入仓库名称',
          trigger: 'blur'
        }],
        plateTime: [{
          required: true,
          message: '请选择上牌时间',
          trigger: 'blur'
        }],
        frameCode: [{
          required: true,
          message: '请输入车架编号',
          trigger: 'blur'
        }, {
          min: 5,
          max: 32,
          message: '长度在 5 到 32 个字符',
          trigger: 'blur'
        }],
        manufacturer: [{
          required: true,
          message: '请输入厂家',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    // 查询仓库
    remoteWarehouse(query) {
      this.warehouses = [];
      if (query && query.length >= 1) {
        this.loading = true;
        this.$api.business.carWarehouse.getList({
          page: 1,
          size: 10,
          name: query
        }).then(res => {
          if (res.code === 200) {
            this.warehouses = res.data.records;
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const loading = ElLoading.service();
          const callBack = res => {
            loading.close();
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
              if (this.$route.meta && this.$route.meta.parent) {
                bus.emit(this.$route.meta.parent, true);
              }
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.formModel.dates && this.formModel.dates.length > 1) {
            this.formModel.insureBegin = this.formModel.dates[0];
            this.formModel.insureEnd = this.formModel.dates[1];
          }
          if (!this.formModel.plateNo) {
            this.formModel.plateTime = null;
          }
          if (this.edit) {
            this.$api.business.deviceFrame.update(this.formModel).then(callBack);
          } else {
            this.$api.business.deviceFrame.create(this.formModel).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.id) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.business.deviceFrame.detail({
        id: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          this.formModel = res.data;
          if (res.data.insureBegin && res.data.insureEnd) {
            this.formModel.dates = [res.data.insureBegin, res.data.insureEnd];
          }
          this.warehouses = [{
            id: res.data.warehouseId,
            name: res.data.warehouseName
          }];
        } else if (res.code === 121601) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};